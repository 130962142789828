import Image from "next/image";
import bellIcon from "assets/bell.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationCount, setNotifications } from "actions/headerNotificationActions";

interface HeaderNotificationProps {
  onNotificationClick: () => void;
}

const HeaderNotification: React.FC<HeaderNotificationProps> = ({
  onNotificationClick,
}) => {
  const reduxState = useSelector((state: any) => state);
  const dispatch: any = useDispatch();

  const notificationCount = reduxState?.headerNotification?.notificationCount || 0;
  const notificationCountTimeInterval = reduxState?.user?.shopifyStoreData?.notificationCountTimeInterval || 300000;  // 5min
  const maxNotificationCountToShow = 99;

  let getNotificationCountIntervalId: any;

  useEffect(() => {
    const param = { unreadOnly: false, limit: 10, offset: 0 };
    dispatch(setNotifications(param));
  }, []);

  useEffect(() => {
    clearGetNotificationCountApiTimeInterval();
    scheduleGetNotificationCountApiTimeInterval(notificationCountTimeInterval);
  
    // Set active/inactive tab change event
    if (typeof window !== undefined ) {
      window.removeEventListener("visibilitychange", handleWindowVisibilitychange);
      window.addEventListener("visibilitychange", handleWindowVisibilitychange)
    }

    return () => window.removeEventListener("visibilitychange", handleWindowVisibilitychange);
  }, [notificationCountTimeInterval]);

  const getNotificationCount = async () => {
    dispatch(setNotificationCount());
  }

  const scheduleGetNotificationCountApiTimeInterval = (time: number) => {
    getNotificationCount();
    getNotificationCountIntervalId = setInterval(getNotificationCount, time);
  }

  const clearGetNotificationCountApiTimeInterval = () => {
    if(getNotificationCountIntervalId) clearInterval(getNotificationCountIntervalId);
  }

  const handleWindowVisibilitychange = () => {
    if (typeof window !== undefined) {
      if (!document?.hidden) scheduleGetNotificationCountApiTimeInterval(notificationCountTimeInterval) 
      else clearGetNotificationCountApiTimeInterval();
    }
  }

  return (
    <div>
      <button
        className="relative w-[24px] h-[24px]"
        onClick={onNotificationClick}
      >
        {notificationCount ? (
          <div className="absolute flex items-center justify-center bg-[#D3264E] text-[10px] leading-[16px] min-w-[18px] h-[14px] rounded-full text-[#ffffff] z-10 left-[40%] top-[-2px] border border-[#FFFFFF]">
            <span className="px-[3px]">{notificationCount > maxNotificationCountToShow ? `${maxNotificationCountToShow}+` : notificationCount}</span>
          </div>
        ) : ''}
        <Image 
          src={bellIcon} 
          alt="Roposoclout Notification" 
        />
      </button>
    </div>
  );
};

export default HeaderNotification;
